// Profile Data & Functions

const moment = require('moment');

export const fields = {
  photo: {
    toggle: true,
    value: null,
    loading: false,
    disabled: false,
  },
  fullname: {
    toggle: true,
    value: null,
    loading: false,
    disabled: false,
    label: 'Nome Completo',
    success: false,
    error: false,
    error: false,
    rules: [v => !!v && v != null || 'Campo obrigatório', v => {
      return /\w{3,}\W(\w{2,}\W\w{3,}|\w{3,})/.test(v) || 'Digite seu nome completo, por favor';
    }],
  },
  email: {
    toggle: true,
    value: null,
    loading: false,
    disabled: false,
    label: 'Email',
    hint: 'Confirme se seu email está correto, por favor.',
    success: false,
    error: false,
    rules: [v => !!v && v != null || 'Campo obrigatório', v => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(v) || 'Email inválido'
    }],
  },
  cpf: {
    toggle: true,
    value: null,
    mask: '###.###.###-##',
    loading: false,
    disabled: true,
    rules: [v => !!v && v!=null || 'Campo obrigatório', v => {
      const pattern = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;
      return pattern.test(v) || 'CPF inválido. Confirme o número, por favor.'
    }],
    label: 'CPF',
    success: false,
    error: false,
  },
  rg: {
    id: {
      toggle: true,
      value: null,
      mask: '########-#',
      loading: false,
      disabled: true,
      label: 'RG',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório'],
    },
    issuer: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Órgão Expeditor',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório'],
    }
  },
  cnh: {
    toggle: true,
    value: null,
    loading: false,
    disabled: true,
    label: 'CNH',
    success: false,
    error: false,
    rules: [v => !!v && v != null || 'Campo obrigatório'],
  },
  phone: {
    toggle: true,
    value: null,
    mask: '+55 (##) #####-####',
    loading: false,
    disabled: false,
    label: 'Celular',
    hint: 'DDD + Telefone',
    success: false,
    error: false,
    rules: [v => !!v && v != null || 'Campo obrigatório', v => {
      const pattern = /[0-9]{13}|^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;
      return pattern.test(v) || 'Celular inválido. Confirme o número, por favor.'
    }],
  },
  birthdate: {
    toggle: true,
    value: null,
    mask: '##/##/####',
    loading: false,
    disabled: false,
    label: 'Data de nascimento',
    hint: 'dd/mm/aaaa',
    success: false,
    error: false,
    rules: [v => !!v && v != null || 'Campo obrigatório'],
  },
  gender: {
    toggle: true,
    value: null,
    loading: false,
    disabled: false,
    label: 'Gênero',
    success: false,
    error: false,
    rules: [v => v != null || 'Campo obrigatório'],
    items: [
      {
        text: 'Masculino',
        value: 'M'
      },
      {
        text: 'Feminino',
        value: 'F'
      },
      {
        text: 'Outro',
        value: 'o'
      },
      {
        text: 'Prefiro não dizer',
        value: '-'
      },
    ]
  },
  gps: {
    toggle: true,
    loading: false,
    disabled: true,
    label: 'GPS (Recomendado)',
  },
  push: {
    toggle: true,
    loading: false,
    disabled: true,
    label: 'Notificações (Recomendado)',
  },
  job: {
    products: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Produtos Mobees',
      placeholder: 'Quais você tem interesse?',
      success: false,
      error: false,
      rules: [v => v != null && v.length > 0 || 'Campo obrigatório'],
      items: [
        {
          text: 'Buzzer Digital',
          value: 'BUZZER_DIGITAL'
        },
        {
          text: 'Buzzer Estático',
          value: 'BUZZER_PRINT'
        },
        {
          text: 'Envelopamento - Vidro',
          value: 'WRAPPING_WINDOW'
        },
        {
          text: 'Envelopamento - Carroceria',
          value: 'WRAPPING_BODYWORK'
        },
      ]
    },
    special_projects: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Projetos Especiais Mobees',
      placeholder: 'Teria interesse em participar?',
      success: false,
      error: false,
      rules: [v => v != null || 'Campo obrigatório'],
      items: [
        {
          text: 'Tenho interesse',
          value: 1
        },
        {
          text: 'Não tenho interesse',
          value: 0
        },
      ],
      hint: 'O que são Projetos Especiais?'
    },
    apps: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Aplicativos de trabalho',
      placeholder: 'Selecione...',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length > 0 || 'Campo obrigatório'],
      items: [
        {
          text: '99',
          value: '99'
        },
        {
          text: 'Uber',
          value: 'UBER'
        },
        {
          text: 'InDriver',
          value: 'INDRIVER'
        },
        {
          text: 'TAXI.RIO',
          value: 'TAXIRIO'
        },
        {
          text: 'Outros',
          value: 'Outros'
        },
        {
          text: 'Nenhum',
          value: 'Nenhum',
          nil: true
        },
      ]
    },
    apps_percent: {
      toggle: false,
      value: null,
      loading: false,
      disabled: false,
      label: 'Quanto utiliza cada aplicativo?',
      placeholder: 'Selecione...',
      success: false,
      error: false,
      rules: [v => !!v && v != null && !Object.values(v).reduce((unset, app) => unset || app.value == null, false) || 'Campo obrigatório'],
      items: [],
      options: [
        10,
        20,
        30,
        40,
        50,
        60,
        70,
        80,
        90,
      ],
    },
    service_model: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Categoria que trabalha',
      placeholder: 'Selecione...',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório'],
      items: [
        {
          text: 'Categoria X',
          subtitle: 'Atendo corridas X e/ou similares',
          value: 'X'
        },
        {
          text: 'Categoria Black',
          subtitle: 'Atendo corridas Black e/ou similares',
          value: 'B'
        },
        {
          text: 'Categoria X e Black',
          subtitle: 'Atendo ambas, porém mais corridas X',
          value: 'Xb'
        },
        {
          text: 'Categoria Black e X',
          subtitle: 'Atendo ambas, porém mais corridas Black',
          value: 'Bx'
        },
        {
          text: 'Taxista',
          value: 'Tx'
        },
      ]
    },
    fulltime: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Motorista de aplicativo 100%?',
      hint: 'Trabalha só com aplicativos (Uber, 99, Cabify...)?',
      success: false,
      error: false,
      rules: [v => v != null || 'Campo obrigatório'],
      items: [
        {
          text: 'É minha única fonte de renda',
          value: 1
        },
        {
          text: 'Já tenho emprego e é uma renda extra para mim',
          value: 0
        },
      ]
    },
    journey: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Média de horas rodadas por dia',
      hint: '',
      success: false,
      error: false,
      rules: [v => v != null || 'Campo obrigatório'],
      items: [
        {
          text: 'Menos que 8 horas',
          value: 2
        },
        {
          text: 'Entre 8 e 12 horas',
          value: 1
        },
        {
          text: 'Mais que 12 horas',
          value: 0
        },
      ]
    },
    region: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Região onde mais roda',
      placeholder: 'Selecione...',
      hint: '',
      success: false,
      error: false,
      rules: [v => v != null && v.length >= 3 || 'Campo obrigatório'],
      items: [
        'Rio de Janeiro/Zona Sul',
        'Rio de Janeiro/Zona Norte',
        'Rio de Janeiro/Zona Oeste',
        'Rio de Janeiro/Centro',
        'Baixada',
        'Niterói',
        'Outros',
      ]
    },
    rating: {
      toggle: true,
      value: null,
      mask: '#.##',
      loading: false,
      disabled: false,
      label: 'Nota aplicativo',
      hint: 'Sua nota no principal aplicativo que você roda',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório',
      v => /^[0-5]*\.[0-9]{2}$/.test(v) || 'Digite no formato X.XX, por favor. Ex: 4.90',
      v => v <= 5 || 'Nota máxima é 5.00'
      ],
    },
    experience: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Experiência',
      hint: 'Quantos anos como motorista de app?',
      max: moment().diff('2014-01-01', 'months'),
      rules: [v => v != null || 'Campo obrigatório', v => v > 0 || 'Campo obrigatório'],

    },
  },
  address: {
    street: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Logradouro',
      hint: 'Ex: Rua da Glória',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length >= 3 || 'Campo obrigatório'],
    },
    number: {
      toggle: true,
      value: null,
      mask: '######',
      loading: false,
      disabled: false,
      label: 'Número',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório'],
    },
    compl: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Complemento',
      hint: 'Ex: Apto 401',
      success: false,
      rules: [v => !v || v.length <= 50 || 'Campo deve ter menos de 50 caracteres']
    },
    neighborhood: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Bairro',
      hint: '',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório'],
    },
    zip: {
      toggle: true,
      value: null,
      mask: '#####-###',
      loading: false,
      disabled: false,
      label: 'CEP',
      hint: '',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório',
      v => /^([\d]{2})\.*([\d]{3})-*([\d]{3})/.test(v) || 'Formato de CEP inválido.'
      ],
    },
    city: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Cidade',
      hint: '',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length >= 3 || 'Campo obrigatório'],
    },
    state: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'UF',
      hint: '',
      success: false,
      error: false,
      rules: [v => !!v && v.length == 2 || 'Campo obrigatório'],
      items: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MG',
        'MS',
        'MT',
        'PA',
        'PB',
        'PE',
        'PI',
        'PR',
        'RJ',
        'RN',
        'RO',
        'RR',
        'RS',
        'SC',
        'SE',
        'SP',
        'TO',
      ]
    },
    country: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'País',
      hint: '',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length >= 3 || 'Campo obrigatório'],
    },
  },
  vehicle: {
    plate: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Placa',
      hint: '',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório', v => v != null && /^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/.test(v) || 'Campo obrigatório'],
    },
    chassis: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Chassi',
      hint: '',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length >= 3 || 'Campo obrigatório'],
    },
    color: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Cor',
      hint: '',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length >= 3 || 'Campo obrigatório'],
    },
    // brand: {
    //   toggle: true,
    //   loading: false,
    //   disabled: true,
    //   label: 'Marca',
    //   hint: '',
    //   success: false,
    //   rules: [v => !!v && v!=null && v.length>=3 || 'Campo obrigatório'],
    //   items: [
    //     'Chevrolet',
    //     'Fiat',
    //     'Ford',
    //     'Honda',
    //     'Hyundai',
    //     'Jac',
    //     'Kia',
    //     'Mitsubishi',
    //     'Nissan',
    //     'Peugeot',
    //     'Renault',
    //     'Toyota',
    //     'Volkswagen',
    //   ]
    // },
    model: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Modelo',
      hint: 'Selecione uma das opções. Se não encontrar seu modelo, selecione "Outro".',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length >= 3 || 'Campo obrigatório',
      v => !!v && v != null || 'Selecione uma das opções. Se não encontrar seu modelo, selecione "Outro".'],
      items: [
        'BYD Dolphin',
        'BYD Dolphin Mini',
        'Chevrolet Agile',
        'Chevrolet Bolt',
        'Chevrolet Celta',
        'Chevrolet Classic',
        'Chevrolet Cobalt',
        'Chevrolet Corsa Classic',
        'Chevrolet Cruze',
        'Chevrolet Equinox',
        'Chevrolet Onix',
        'Chevrolet Onix +',
        'Chevrolet Prisma',
        'Chevrolet Spin',
        'Citroën Aircross',
        'Citroën C3',
        'Citroën C3 Picasso',
        'Citroën C4',
        'Citroën C4 Lounge',
        'Fiat 500',
        'Fiat Argo',
        'Fiat Bravo',
        'Fiat Cronos',
        'Fiat Doblò',
        'Fiat Grand Siena',
        'Fiat Idea',
        'Fiat Linea',
        'Fiat Mobi',
        'Fiat Palio',
        'Fiat Palio Weekend',
        'Fiat Punto',
        'Fiat Siena',
        'Fiat Toro',
        'Fiat Uno',
        'Ford Fusion',
        'Ford Edge',
        'Ford EcoSport',
        'Ford Fiesta',
        'Ford Focus',
        'Ford Ka',
        'Ford Ka +',
        'Honda Accord',
        'Honda City',
        'Honda Civic',
        'Honda CR-V',
        'Honda Fit',
        'Honda HRV',
        'Honda WRV',
        'Hyundai Azera',
        'Hyundai Creta',
        'Hyundai Elantra',
        'Hyundai HB20',
        'Hyundai HB20S',
        'Hyundai HB20X',
        'Hyundai ix20',
        'Hyundai ix35',
        'Hyundai Santa Fé',
        'Hyundai Sonata',
        'Hyundai Tucson',
        'Hyundai Veloster',
        'Hyundai Veracruz',
        'JAC J3',
        'JAC J5',
        'Kia Bongo',
        'Kia Cadenza',
        'Kia Carens',
        'Kia Carnival',
        'Kia Cerato',
        'Kia Optima',
        'Kia Picanto',
        'Kia Quoris',
        'Kia Rio',
        'Kia Sorento',
        'Kia Soul',
        'Kia Sportage',
        'Kia Stinger',
        'Mitsubishi Pajero',
        'Mitsubishi Lancer',
        'Nissan Grand Livina',
        'Nissan Kicks',
        'Nissan Leaf',
        'Nissan Livina',
        'Nissan March',
        'Nissan Sentra',
        'Nissan Tiida',
        'Nissan Versa',
        'Peugeot 2008',
        'Peugeot 207',
        'Peugeot 207 Passion',
        'Peugeot 208',
        'Peugeot 3008',
        'Peugeot 408',
        'Peugeot 5008',
        'Renault Captur',
        'Renault Clio',
        'Renault Duster',
        'Renault Fluence',
        'Renault Kwid',
        'Renault Logan',
        'Renault Sandero',
        'Toyota Corolla',
        'Toyota Etios',
        'Toyota Prius',
        'Toyota Yaris',
        'Volkswagen Space Fox',
        'Volkswagen Fox',
        'Volkswagen Gol',
        'Volkswagen Golf',
        'Volkswagen Jetta',
        'Volkswagen Polo',
        'Volkswagen T-Cross',
        'Volkswagen Tiguan',
        'Volkswagen Up',
        'Volkswagen Virtus',
        'Volkswagen Voyage',
        'Outro'
      ]
    },
    fuel: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Combustível',
      placeholder: 'Selecione...',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length > 0 || 'Campo obrigatório'],
      items: ['GNV', 'Gasolina', 'Álcool', 'Elétrico']
    },
    parking: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Estacionamento',
      hint: '',
      success: false,
      error: false,
      rules: [v => v != null || 'Campo obrigatório'],
      items: [
        {
          text: 'Garagem coberta',
          value: 0,
        },
        {
          text: 'Garagem descoberta',
          value: 1,
        },
        {
          text: 'Na rua',
          value: 2,
        },
      ]
    },
    year: {
      toggle: true,
      value: null,
      mask: '####',
      loading: false,
      disabled: true,
      label: 'Ano',
      success: false,
      error: false,
      rules: [v => v != null || 'Campo obrigatório',
      v => /^(19[5-9]\d|20[0-2]\d)$/.test(v) || 'Informe um ano válido. Ex: ' + moment().year()
      ],
    },
    renavam: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Renavam',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório'],
    },
    isOwner: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Dono',
      success: false,
      error: false,
      rules: [v => v != null || 'Campo obrigatório'],
      items: [
        {
          text: 'Veículo em meu nome',
          value: 1
        },
        {
          text: 'Veículo em nome de outra pessoa',
          value: 0
        },
        {
          text: 'Veículo alugado de locadora',
          value: 2
        },
      ]
    },
    rental: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Locadora',
      rules: [v => v != null && v.length > 3 || 'Campo obrigatório'],
      success: false
    },
    ownerName: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Nome do Proprietário',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length > 3 || 'Campo obrigatório'],
    },
    ownerEmail: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Email',
      success: false,
      error: false,
      rules: [v => !!v && v != null || 'Campo obrigatório', (v, $) => {
        const email = !_.isNil($) ? $.profile.email : profile.email;
        return v != email || 'Digite aqui o email do proprietário do veículo, por favor.'
      }, v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'Email inválido'
      }],
    },
    ownerRg: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'RG do Proprietário',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length > 6 || 'Campo obrigatório'],
    },
    ownerOrg_exp: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Orgão Expeditor',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length > 3 || 'Campo obrigatório'],
    },
    ownerCpf: {
      toggle: true,
      value: null,
      mask: '###.###.###-##',
      loading: false,
      disabled: true,
      label: 'CPF do Proprietário',
      success: false,
      error: false,
      rules: [v => v != null || 'Campo obrigatório', v => {
        const pattern = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;
        return pattern.test(v) || 'CPF inválido. Confirme o número, por favor.'
      }],
    },
    ownerRelative: {
      toggle: true,
      value: null,
      loading: false,
      disabled: true,
      label: 'Parentesco do Proprietário',
      success: false,
      error: false,
      rules: [v => !!v && v != null && v.length >= 3 || 'Campo obrigatório'],
    },
  },
  payment: {
    bank: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Banco',
      rules: [v => v != null || 'Campo obrigatório'],
      hint: '',
      success: false
    },
    branch: {
      toggle: true,
      value: null,
      mask: ['###', '###-#', '###-##'], // mask freezes the browser
      loading: false,
      disabled: false,
      label: 'Agência',
      rules: [v => v != null || 'Campo obrigatório', v => v != '' || 'Campo obrigatório', v => /^[0-9]{3,5}$/.test(v) || 'Agência inválida'],
      success: false
    },
    account: {
      toggle: true,
      value: null,
      mask: ['###-#', '####-#', '#####-#', '######-#'], // mask freezes the browser
      loading: false,
      disabled: false,
      label: 'Conta Corrente',
      rules: [v => v != null || 'Campo obrigatório', v => v != '' || 'Campo obrigatório', v => /^[0-9]{4,8}$/.test(v) || 'Conta inválida'],
      hint: 'Número da sua conta sem o dígito',
      success: false
    },
    digit: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Dígito Conta',
      rules: [v => v != null || 'Campo obrigatório', v => v != '' || 'Campo obrigatório', v => /^[0-9]{1}$/.test(v) || 'Dígito de conta inválido'],
      hint: 'Apenas o dígito da conta',
      success: false
    },
    type: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Tipo de Conta',
      rules: [v => v != null || 'Campo obrigatório'],
      success: false,
      error: false,
      items: [
        {
          text: 'Corrente (em seu nome)',
          value: 'COR'
        },
        {
          text: 'Poupança',
          value: 'POU',
          disabled: true
        },
      ]
    },
    compl: {
      toggle: true,
      value: null,
      loading: false,
      disabled: false,
      label: 'Complemento da Conta Corrente',
      success: false
    },
  },
}

export const completion = {
  'ACC': {
    personal: {
      percent: 0,
      inherit: [],
      required: [
        'fullname',
        'email',
        'phone',
        'birthdate',
        'gender',
        'address.zip',
        'address.city',
        'address.state',
        'address.country',
      ],
      disabled: [],
    },
    job: {
      percent: 0,
      inherit: [],
      required: [
        'job.apps',
        'job.apps_percent',
        'job.service_model',
        'job.fulltime',
        'job.journey',
        'job.region',
        'job.rating',
        'job.experience',
      ],
      disabled: [],
    },
    vehicle: {
      percent: 0,
      inherit: [],
      required: [
        // 'vehicle.brand',
        'vehicle.model',
        'vehicle.year',
        'vehicle.parking',
        'vehicle.isOwner',
        // 'vehicle.ownerRelative',
      ],
      disabled: [],
    },
    payment: {
      percent: 0,
      required: [],
      disabled: [],
    }
  },
  'FDB': {
    personal: {
      percent: 0,
      inherit: [],
      required: [
        'fullname',
        'cpf',
        'email',
        'phone',
        'birthdate',
        'gender',
        'address.zip',
        'address.city',
        'address.state',
        'address.country',
      ],
      disabled: [],
    },
    job: {
      percent: 0,
      inherit: [],
      required: [
        'job.apps',
        'job.apps_percent',
        'job.service_model',
        'job.fulltime',
        'job.journey',
        'job.region',
        'job.rating',
        'job.experience',
      ],
      disabled: [],
    },
    vehicle: {
      percent: 0,
      inherit: [],
      required: [
        // 'vehicle.brand',
        'vehicle.model',
        'vehicle.year',
        'vehicle.parking',
        'vehicle.isOwner',
        // 'vehicle.ownerRelative',
      ],
      disabled: [],
    },
    payment: {
      percent: 0,
      required: [],
      disabled: [],
    }
  },
  'SIG': {
    personal: {
      percent: 0,
      inherit: ['ACC'],
      required: [
        'rg.id',
        'rg.issuer',
        'cnh',
        'address.neighborhood',
        'address.street',
        'address.number',
        'address.compl',
      ],
      disabled: [],
    },
    job: {
      percent: 0,
      inherit: ['ACC'],
      required: [],
      disabled: [],
    },
    vehicle: {
      percent: 0,
      inherit: ['ACC'],
      required: [
        'vehicle.plate',
        'vehicle.chassis',
        'vehicle.color',
        'vehicle.renavam',
      ],
      disabled: [],
    },
    payment: {
      percent: 0,
      inherit: ['ACC'],
      required: [],
      disabled: [],
    },
  },
  'ENR': {
    personal: {
      percent: 0,
      inherit: ['ACC'],
      required: [
        'rg.id',
        'rg.issuer',
        'cnh',
        'address.neighborhood',
        'address.street',
        'address.number',
        'address.compl',
      ],
      disabled: [],
    },
    job: {
      percent: 0,
      inherit: ['ACC'],
      required: [],
      disabled: [],
    },
    vehicle: {
      percent: 0,
      inherit: ['ACC'],
      required: [
        'vehicle.plate',
        'vehicle.color',
        'vehicle.chassis',
        'vehicle.renavam',
        // 'vehicle.ownerName',
        // 'vehicle.ownerRg',
        // 'vehicle.ownerOrg_exp',
        // 'vehicle.ownerCpf',
      ],
      disabled: [],
    },
    payment: {
      percent: 0,
      required: [
        'payment.bank',
        'payment.branch',
        'payment.account',
        'payment.digit',
        'payment.type',
        'payment.compl',
      ],
      disabled: [],
    }
  },
  'FDD': {
    personal: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    job: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    vehicle: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    payment: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
  },
  'REA': {
    personal: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    job: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    vehicle: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    payment: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
  },
  'SCH': {
    personal: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    job: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    vehicle: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
    payment: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [],
      disabled: [],
    },
  },
  'ACT': {
    personal: {
      percent: 0,
      inherit: [],
      required: [],
      disabled: [],
    },
    job: {
      percent: 0,
      inherit: [],
      required: [
        'job.products',
        'job.special_projects',
        'job.apps',
        'job.apps_percent',
      ],
      disabled: [],
    },
    vehicle: {
      percent: 0,
      inherit: ['ENR', 'ACC'],
      required: [
        'vehicle.fuel',
      ],
      disabled: [],
    },
    payment: {
      percent: 0,
      inherit: [],
      required: [],
      disabled: [],
    },
  }
}

export const defaults = {
  hive: {
    'id': 1,
    'name': 'Rio',
    'address': 'R. Viêira Ferreira, 55 - Bonsucesso',
    'city': 'Rio de Janeiro/RJ'
  }
}

export default {
  fields,
  completion,
  defaults
}